.trigger {

  font-size: 18px;

  line-height: 64px;

  padding: 0 24px;

  cursor: pointer;

  transition: color 0.3s;

}

 

.trigger:hover {

  color: #1890ff;

}

 

.logo {
  /*display: inline-block;
  height:64px;
  line-height: 64px;
  padding: 0 0 0 24px;
  font-size: 20px;
  cursor: pointer;
  vertical-align: top;
  cursor: pointer;*/

  
  height: 42px;
  background: rgba(3, 2, 2, 0.2);
  margin: 16px;
  overflow: hidden;

  /*background: rgba(255, 255, 255, 0.363);
  margin: 16px;
  position: relative;
  overflow: hidden;
  padding-left:auto;
  transition: all 0.3s;
  font-size: 20px;*/
}

 

.logo h1 {

  display: inline-block;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 800;
  font-size: 16px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

 

.logo img {

  display: inline-block;

  height: 32px;

  vertical-align: middle;

}

