.login-container {
    max-width: 520px;
    margin: 0 auto;
    margin: 0 auto;
    margin-top: 40px;
    padding: 16px;
}

.login-form-button {
    width: 100%;
}